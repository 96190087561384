import './BlackLine.css';

function BlackLine() {
    return (
      <div className="black-line">
      </div>
    );
  }
  
  export default BlackLine;
  